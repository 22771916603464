<template>
  <div
    class="searchResult"
    v-watermark
  >
    <commonNavBar :title="'设备卡号查询'"></commonNavBar>
    <!-- 加载中 -->
    <van-overlay
      :show="loadingShow"
      class-name="myLoading"
    >
      <van-loading
        text-color="#0094ff"
        color="#0094ff"
        vertical
      >加载中...</van-loading>
    </van-overlay>
    <div class="searchBox">
      <form action="/">
        <van-search
          :value="$route.query.text"
          show-action
          :clearable="false"
          readonly
          @click="$router.back()"
        >
          <template #action>
            <div @click="$router.back()">取消</div>
          </template>
        </van-search>
      </form>
      <div class="siftDp">
        <van-dropdown-menu active-color="#3ea7f0">
          <van-dropdown-item
            v-model="status"
            :options="statusOption"
            :title="statusTitle"
            @change="statusChange"
            :disabled="loadingShow"
            @open="statusOpened"
          />
          <van-dropdown-item
            ref="equipmentTerminalOperatorItem"
            :title="equipmentTerminalOperatorTitle"
            :disabled="loadingShow"
            @open="equipmentTerminalOperatorSelect = ConfirmequipmentTerminalOperatorSelect"
          >
            <van-checkbox-group v-model="equipmentTerminalOperatorSelect">
              <van-cell-group>
                <van-cell
                  v-for="(item, index) in equipmentTerminalOperator"
                  clickable
                  :key="item.id"
                  :title="item.name"
                  @click="equipmentTerminalOperatorToggle(index)"
                >
                  <template #right-icon>
                    <van-checkbox
                      :name="item.id"
                      ref="equipmentTerminalOperatorCheckboxes"
                    />
                  </template>
                </van-cell>
              </van-cell-group>
            </van-checkbox-group>
            <div class="myButton">
              <van-button
                @click="equipmentTerminalOperatorSelect = []"
                color="#969799"
                block
                size="small"
              >
                重置
              </van-button>
              <van-button
                type="info"
                block
                size="small"
                @click="equipmentTerminalOperatorItemClosed"
              >
                确认
              </van-button>
            </div>
          </van-dropdown-item>
          <van-dropdown-item
            :title="moreTitle"
            ref="moreItem"
            :disabled="loadingShow"
            @open="openMoreOpened"
          >
            <div class="more">
              <div class="moreItem">
                <div class="moreLabel">卡号类型</div>
                <div class="itemValue">
                  <van-field name="cardNumberTypeSelect">
                    <template #input>
                      <van-checkbox-group
                        v-model="cardNumberTypeSelect"
                        direction="horizontal"
                      >
                        <van-checkbox
                          :name="item.id"
                          shape="square"
                          v-for="(item, index) in cardNumberType"
                          :key="index"
                        >
                          <template #default>
                            <van-tag
                              :color="cardNumberTypeSelect.includes(item.id)
                                ? '#3e89fa'
                                : '#969799'
                                "
                              size="medium"
                            >{{ item.name }}</van-tag>
                          </template>
                          <template #icon>
                            <div></div>
                          </template>
                        </van-checkbox>
                      </van-checkbox-group>
                    </template>
                  </van-field>
                </div>
              </div>
              <div class="moreItem">
                <div class="moreLabel">支持设备</div>
                <div class="itemValue">
                  <van-field name="cardSupportEquipmentSelect">
                    <template #input>
                      <van-checkbox-group
                        v-model="cardSupportEquipmentSelect"
                        direction="horizontal"
                      >
                        <van-checkbox
                          :name="item.id"
                          shape="square"
                          v-for="(item, index) in cardSupportEquipment"
                          :key="index"
                        >
                          <template #default>
                            <van-tag
                              :color="cardSupportEquipmentSelect.includes(item.id)
                                ? '#3e89fa'
                                : '#969799'
                                "
                              size="medium"
                            >{{ item.name }}</van-tag>
                          </template>
                          <template #icon>
                            <div></div>
                          </template>
                        </van-checkbox>
                      </van-checkbox-group>
                    </template>
                  </van-field>
                </div>
              </div>
            </div>
            <div class="myButton">
              <van-button
                color="#969799"
                block
                size="small"
                @click="
                  cardNumberTypeSelect = [];
                cardSupportEquipmentSelect = [];
                "
              >
                重置
              </van-button>
              <van-button
                type="info"
                block
                size="small"
                @click="confirmMoreClick"
              >
                确认
              </van-button>
            </div>
          </van-dropdown-item>
          <van-dropdown-item
            v-model="sequence"
            :options="sequenceOption"
            :title="sequenceTitle"
            :disabled="loadingShow"
            @change="sequenceChange"
            @open="sequenceOpened"
          />
        </van-dropdown-menu>
      </div>
      <div
        class="searchBar"
        v-if="cardNumberList.length"
      >
        <span class="label">搜索结果:共{{ total }}条</span>
      </div>
      <!-- 结果列表 -->
      <div
        class="listBox"
        v-if="cardNumberList.length"
        @scroll="onScroll"
        ref="listBox"
      >
        <van-list
          v-model="loading"
          :finished="finished"
          finished-text="没有更多了"
          @load="onLoad"
          :error.sync="error"
          error-text="请求失败,点击重新加载!"
        >
          <!-- 卡片 -->
          <div
            class="myCard"
            v-for="(item, index) in cardNumberList"
            :key="index"
            @click="$router.push(`/workTable/cardNumberAddressInfo/${item.id}`)"
          >
            <div class="name">
              <span class="label">{{ item.cardNumber || "--" }}</span>
              <van-tag
                type="success"
                v-if="item.status === 0"
              >已激活</van-tag>
              <van-tag
                type="danger"
                v-if="item.status === 1"
              >停用</van-tag>
              <van-tag
                color="#aaaaaa"
                v-if="item.status === 2"
              >未激活</van-tag>
            </div>
            <p>运营商:{{ item.operator || "--" }}</p>
            <p>卡号类型:{{ item.cardNumberType || "--" }}</p>
            <p>卡号来源:{{ item.supplier || "--" }}</p>
            <p>绑定设备:{{ item.bindEquipment || "--" }}</p>
          </div>
        </van-list>
      </div>
      <!-- 空结果 -->
      <div
        class="empty"
        v-else
      >
        <van-empty
          image="search"
          description="没有符合条件的数据"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { getCardNumberList } from '@/api/cardNumber'
import { getDictionaryByTypeList } from '@/api/system'

export default {
  name: 'searchResult',
  data() {
    return {

      cardNumberList: [],
      loading: false,
      loadingShow: false,
      finished: false,
      top: 0,
      pageNum: 1,
      pageSize: 10,
      total: 0,
      error: false,
      statusOption: [
        { text: '全部', value: 'all' },
        { text: '未激活', value: 2 },
        { text: '已激活', value: 0 },
        { text: '停用', value: 1 }
      ],
      status: '',
      statusTitle: '状态',
      sequenceOption: [
        { text: '默认排序', value: 'default' },
        { text: '更新时间由近及远', value: 'desc' },
        { text: '更新时间由远及近', value: 'asc' }
      ],
      sequence: '',
      sequenceTitle: '默认排序',
      equipmentTerminalOperator: [],
      equipmentTerminalOperatorSelect: [],
      ConfirmequipmentTerminalOperatorSelect: [],
      equipmentTerminalOperatorTitle: '运营商',
      moreTitle: '更多',
      cardNumberType: [],
      cardSupportEquipment: [],
      cardNumberTypeSelect: [],
      cardSupportEquipmentSelect: [],
      confirmcardNumberTypeSelect: [],
      confirmcardSupportEquipmentSelect: []
    }
  },
  // 激活页面
  activated() {
    if (this.$refs.listBox) {
      this.$refs.listBox.scrollTop = this.top
    }
  },
  // 路由进入页面
  beforeRouteEnter(to, from, next) {
    if (from.path.includes('/workTable/cardNumberAddressInfo')) {
      next((vm) => {
        if (!vm.cardNumberList.length) {
          vm.getDictionaryByTypeList()
          vm.onLoad()
        }
      })
    } else {
      next((vm) => {
        Object.assign(vm.$data, vm.$options.data.call(vm))
        vm.getDictionaryByTypeList()
        vm.onLoad()
      })
    }
  },
  watch: {
    status(val) {
      if (val === 'all') {
        this.statusTitle = '状态'
      } else if (val === 0) {
        this.statusTitle = '已激活'
      } else if (val === 1) {
        this.statusTitle = '停用'
      } else if (val === 2) {
        this.statusTitle = '未激活'
      } else {
        this.statusTitle = '状态'
      }
    },
    sequence(val) {
      if (val === '') {
        this.sequenceTitle = '默认排序'
      } else if (val === 'default') {
        this.sequenceTitle = '默认排序'
      } else if (val === 'asc') {
        this.sequenceTitle = '更新时间由远及近'
      } else if (val === 'desc') {
        this.sequenceTitle = '更新时间由近及远'
      } else {
        this.sequenceTitle = '默认排序'
      }
    },
    ConfirmequipmentTerminalOperatorSelect(val) {
      if (!val.length) {
        this.equipmentTerminalOperatorTitle = '运营商'
      } else if (val.length === 1) {
        this.equipmentTerminalOperatorTitle =
          this.equipmentTerminalOperator[
            this.equipmentTerminalOperator.findIndex((item) => {
              return item.id === val[0]
            })
          ].name
      } else if (val.length > 1) {
        this.equipmentTerminalOperatorTitle = '运营商(多选)'
      } else {
        this.equipmentTerminalOperatorTitle = '运营商'
      }
    }
  },
  methods: {
    // 加载列表
    async onLoad() {
      if (this.pageNum === 1) {
        this.loadingShow = true
      }
      try {
        const { data } = await getCardNumberList({
          'page.current': this.pageNum,
          'page.size': this.pageSize,
          'sort.key': '0',
          'sort.value':
            this.sequence === '' || this.sequence === 'default'
              ? 'desc'
              : this.sequence,
          search: this.$route.query.text,
          status:
            this.status === 'all' || this.status === '' ? null : this.status,
          equipment:
            this.confirmcardSupportEquipmentSelect.length
              ? this.confirmcardSupportEquipmentSelect.toString()
              : null,
          cardNumberType: this.confirmcardNumberTypeSelect.length
            ? this.confirmcardNumberTypeSelect.toString()
            : null,
          operator: this.ConfirmequipmentTerminalOperatorSelect.length
            ? this.ConfirmequipmentTerminalOperatorSelect.toString()
            : null
        })
        this.total = data.data.total
        this.pageNum = this.pageNum + 1
        // 追加数据
        const results = data.data.records
        this.cardNumberList.push(...results)
        this.loading = false
        if (!results.length) {
          this.finished = true
        }
        if (this.loadingShow) {
          this.loadingShow = false
        }
      } catch (error) {
        if (error.message === '取消请求') {
          this.error = false
          return
        }
        // 加载状态结束
        this.loading = false
        this.error = true
        if (this.loadingShow) {
          this.loadingShow = false
        }
        if (
          error?.response?.status === 401
        ) {
          return
        }
        if (error.message.includes('timeout')) {
          if (this.$route.path === '/workTable/cardNumberResult') {
            this.$toast.fail({
              message: '网络不好，请稍后再试!',
              duration: 500
            })
          }
        }
      }
    },
    onScroll(e) {
      this.top = e.target.scrollTop
    },
    // 批量获取数据字典下拉列表
    async getDictionaryByTypeList() {
      const {
        data: { data }
      } = await getDictionaryByTypeList({
        types: [
          'equipment-terminal-operator',
          'cardNumber-type',
          'card-support-equipment'
        ].toString()
      })
      this.equipmentTerminalOperator = data['equipment-terminal-operator']
      this.cardNumberType = data['cardNumber-type']
      this.cardSupportEquipment = data['card-support-equipment']
    },
    // 排序变化
    sequenceChange() {
      this.pageNum = 1
      this.error = false
      this.top = 0
      this.cardNumberList = []
      if (this.$refs.listBox) {
        this.$refs.listBox.scrollTop = 0
      }
      this.finished = false
      this.onLoad()
    },
    // 状态变化
    statusChange() {
      this.pageNum = 1
      this.error = false
      this.top = 0
      this.cardNumberList = []
      if (this.$refs.listBox) {
        this.$refs.listBox.scrollTop = 0
      }
      this.finished = false
      this.onLoad()
    },
    // 打开排序
    sequenceOpened() {
      if (this.sequence === '') {
        this.sequence = 'default'
      }
    },
    // 打开状态下拉
    statusOpened() {
      if (this.status === '') {
        this.status = 'all'
      }
    },
    // 打开更多下拉框
    openMoreOpened() {
      this.cardNumberTypeSelect = this.confirmcardNumberTypeSelect
      this.cardSupportEquipmentSelect = this.confirmcardSupportEquipmentSelect
    },
    // 更多确认
    confirmMoreClick() {
      this.confirmcardNumberTypeSelect = this.cardNumberTypeSelect
      this.confirmcardSupportEquipmentSelect = this.cardSupportEquipmentSelect
      if (
        (!this.confirmcardNumberTypeSelect.length &&
          !this.confirmcardSupportEquipmentSelect.length) ||
        (this.confirmcardNumberTypeSelect.length === 1 &&
          !this.confirmcardSupportEquipmentSelect.length) ||
        (!this.confirmcardNumberTypeSelect.length &&
          this.confirmcardSupportEquipmentSelect.length === 1)
      ) {
        this.moreTitle = '更多'
      } else {
        this.moreTitle = '更多(多选)'
      }
      this.pageNum = 1
      this.error = false
      this.top = 0
      this.cardNumberList = []
      if (this.$refs.listBox) {
        this.$refs.listBox.scrollTop = 0
      }
      this.finished = false
      this.onLoad()
      this.$refs.moreItem.toggle(false)
    },
    // 运营商选择
    equipmentTerminalOperatorToggle(index) {
      this.$refs.equipmentTerminalOperatorCheckboxes[index].toggle()
    },
    // 关闭运营商
    equipmentTerminalOperatorItemClosed() {
      this.ConfirmequipmentTerminalOperatorSelect = this.equipmentTerminalOperatorSelect
      this.pageNum = 1
      this.error = false
      this.top = 0
      this.cardNumberList = []
      if (this.$refs.listBox) {
        this.$refs.listBox.scrollTop = 0
      }
      this.finished = false
      this.onLoad()
      this.$refs.equipmentTerminalOperatorItem.toggle(false)
    }
  }
}
</script>

<style lang="scss" scoped>
.searchResult {
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: #eeeeee;

  ::v-deep {
    .myLoading {
      top: 148px;
      bottom: 0;
      height: auto;

      .van-loading {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }

    .searchBox {
      height: calc(100% - 46px);
      overflow: auto;

      .van-search__label {
        .van-dropdown-menu {
          height: 100%;
          width: 100%;
          background-color: transparent;
          overflow: hidden;

          .van-dropdown-menu__bar {
            height: 100%;
            width: 100%;
            box-sizing: border-box;
            background-color: transparent;
            box-shadow: none;

            .van-ellipsis {
              padding-right: 5px;
              font-size: 14px;
            }
          }

          .van-dropdown-menu__title::after {
            right: 1px;
          }
        }

        .van-dropdown-item {
          .van-dropdown-item__content {
            .van-dropdown-item__option {
              .van-cell__title {
                padding-left: 25px;
                font-size: 14px;
              }
            }
          }
        }

        .van-dropdown-menu__item--disabled {
          .van-dropdown-menu__title {
            color: #323233;
          }
        }
      }

      .siftDp {
        overflow: hidden;

        .van-dropdown-menu {
          >div {
            &:nth-child(4) {
              .van-dropdown-item__content {

                .more {

                  .moreLabel {
                    font-size: 16px;
                    padding-left: 16px;
                  }

                  .itemValue {
                    .van-radio--horizontal {
                      width: auto;
                      margin-bottom: 0;
                    }
                  }
                }

              }
            }
          }

          .van-dropdown-menu__bar {
            box-shadow: none;

            .van-dropdown-menu__item {
              &:nth-child(2) {
                .van-dropdown-menu__title {
                  .van-ellipsis {
                    max-width: none;
                  }
                }
              }

              &:nth-child(3) {
                .van-dropdown-menu__title {
                  .van-ellipsis {
                    max-width: none;
                  }
                }
              }

              &:nth-child(1) {
                .van-dropdown-menu__title {
                  .van-ellipsis {
                    max-width: none;
                  }
                }
              }

              .van-dropdown-menu__title {
                .van-ellipsis {
                  max-width: 4.5em;
                }
              }
            }
          }
        }

        .van-radio--horizontal {
          margin-right: 0;
          width: 25%;
          margin-bottom: 10px;
        }

        .van-checkbox--horizontal {
          margin-right: 0;
          margin-bottom: 10px;
        }

        .myButton {
          padding: 5px 16px;
          display: flex;

          .van-button--small {
            margin: 0 5px;
          }

          .van-tree-select__item--active {
            color: #3ea7f0;
          }
        }
      }

      .searchBar {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 10px;
        height: 24px;
        padding-bottom: 5px;
        background-color: #eeeeee;

        .label {
          font-size: 12px;
          color: #aaaaaa;
          padding-top: 5px;
        }

        .myIcon {
          width: 20px;
          height: 20px;
        }
      }
    }

    .empty {
      height: calc(100% - 54px - 24px - 48px);
      position: relative;

      .van-empty {
        position: absolute;
        width: 100%;
        transform: translateY(-50%);
        top: 50%;
      }
    }

    .listBox {
      height: calc(100% - 54px - 24px - 48px);
      overflow: auto;
      padding: 0 7px;

      // padding-top: 10px;
      // padding: 9px 0;
      // padding-bottom: 0px;
      .myCard {
        // margin-bottom: 9px;
        // background-color: white;
        margin-bottom: 10px;
        background-color: white;
        border-radius: 5px;
        padding: 5px;

        .name {
          font-size: 0;
          margin-bottom: 5px;

          .label {
            font-size: 14px;
            margin-right: 10px;
            font-weight: 700;
          }
        }

        >p {
          font-size: 12px;
          color: #969799;
          margin-bottom: 5px;
        }
      }
    }
  }
}
</style>
